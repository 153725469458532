import axios from "axios";

const API_URL = "https://pipelead.prospr.fr/inputs/votre-assurance-decennale";
const TOKEN = "oLoh5ainiSiiga9johy3we5jee5Viif4";

//const API_URL = "http://localhost:8080/inputs/votre-assurance-decennale";
//const TOKEN = "oLoh5ainiSiiga9johy3we5jee5Viif4";

const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

export const jsonReviver = (_key: any, value: any) => {
  if (typeof value === "string") {
    if (dateRegex.test(value)) {
      return new Date(value);
    }
  }
  return value;
};

export const post = (path: string, data: any) =>
  axios
    .post(API_URL + path, data, {
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      },
    })
    .then(res => res.data);
