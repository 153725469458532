const pad2 = str =>
  str.length == 0 ? "00" : str.length == 1 ? "0" + str : str;

export const cleanDateString = (v: string): string => {
  if (/^(\d\d)$/.test(v) || /^(\d\d)\/(\d\d)$/.test(v)) {
    v += "/";
  }
  v = v.replace(/^(\d{1})\//g, "0$1/");
  v = v.replace(/^(\d\d\/)(\d{1})\//g, "$10$2/");
  v = v.replace(/([^\d\/])/g, "");
  v = v.replace(/\/\//g, "/");
  v = v.replace(/^\//, "");
  v = v.substr(0, 10);
  v = v.replace(/^(\d\d)(\d)/, "$1/$2");
  v = v.replace(/^(\d\d)\/(\d\d)(\d)/, "$1/$2/$3");
  const reg = /^(\d\d)\/(\d\d)\/(\d\d\d\d)$/;
  if (reg.test(v)) {
    const parts = v.split("/");
    const date = new Date(
      Math.min(new Date().getFullYear() + 1, parseInt(parts[2])),
      Math.min(11, parseInt(parts[1]) - 1),
      Math.min(31, parseInt(parts[0]))
    );
    v =
      pad2(date.getDate().toString()) +
      "/" +
      pad2((date.getMonth() + 1).toString()) +
      "/" +
      date.getFullYear().toString();
  }
  return v;
};

export const cleanPhone = (s: string | ""): string => {
  const parts = (s || "")
    .replace(/\+33/g, "0")
    .replace(/[ \.]/g, "")
    .replace(/\D/g, "")
    .match(/.{1,2}/g);
  return parts ? parts.join(" ").substr(0, 14) : "";
};
